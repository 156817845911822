<template>
  <div class="container">
    <!-- Login Form -->
    <div v-if="!entry">
      <div class="row justify-content-center">
        <div class="col-md-4 mt-5">
          <div class="card">
            <div class="card-header text-white text-center" style="background: #214439;">
              <br>
              <img src="https://lia.ly/wp-content/uploads/2021/01/LOGO-img-ar.svg" alt="Libyan Investment Corporation"
                height="80">
              <br><br>
              <h3>مباردة تمكين</h3>
              <p> اهلا و سهلا بكم </p>
            </div>
            <div class="card-body" dir="rtl">
              <form @submit.prevent="login">
                <div class="mb-3">
                  <label for="entry_id" class="form-label"> الكود</label>
                  <input type="text" class="form-control" id="entry_id" v-model="entry_id" required>
                </div>
                <div class="mb-3">
                  <label for="item_key" class="form-label">كلمة المرور</label>
                  <input type="password" class="form-control" id="item_key" v-model="item_key" required>
                </div>
                <div class="d-grid gap-2 col-12 justify-content-center">
                  <p v-if="errorMessage" class="mt-3 text-danger text-center">{{ errorMessage }}</p>
                  <button class="button-17" type="submit"> دخول للمنصة </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Entry Details Table -->
    <div v-if="entry">
      <div class="header mb-4">
        <img src="@/assets/img/logo.png" alt="Logo">
        <h3>المؤسسة الليبية للإستثمار</h3>
        <h4>Libyan Investment Authority</h4>
      </div>

      <div class="containerDashboard">
        <div class="title">مبادرة تمكين لدعم القدرات الشبابية</div>
        <li class="name">{{ getFieldValue(434) }}</li>
        <div class="steps" v-if="getFieldValue(469) == 1">

          <!-- <div class="step">
            <div class="circle" style="background-color: gray;">04</div>
            <p>قبول</p>
          </div>
          <div class="line"></div>
          <div class="step">
            <div class="circle" style="background-color: gray;">03</div>
            <p>المقابلة الشخصية</p>
          </div>
          <div class="line"></div>
          <div class="step">
            <div class="circle completed"><i class="fa-solid fa-check"></i></div>
            <p>تحت الإجراء</p>
          </div>
          <div class="line"></div>
          <div class="step">
            <div class="circle completed"><i class="fa-solid fa-check"></i></div>
            <p>تم استلام الطلب</p>
          </div> -->

          <div class="content">
            <p>
              نتقدم لك بجزيل الشكر والتقدير على الوقت الذي أوليتنا إياه، وإننا نأسف أن نعلمك بأنه لم يتم اختيارك
              لاستكمال إجراءات التقديم حالياً، هذا وسيتم الاحتفاظ ببيانات السيرة الذاتية التي تخصك ضمن قاعدة البيانات
              الخاصة بينا لاي فرصة مستقبلاً
              <br>
              <strong>
                نقدّر جهودكم ونتمنى لكم التوفيق
                <br>
                مع خالص الشكر</strong>
            </p>
          </div>

        </div>
        <div class="steps" v-if="getFieldValue(469) == 2">
          <!-- <div class="step">
            <div class="circle" style="background-color: gray;">04</div>
            <p>قبول</p>
          </div>
          <div class="line"></div>
          <div class="step">
            <div class="circle">03</div>
            <p>المقابلة الشخصية <br> {{ getFieldValue(470) }}</p>
            <button v-if="!meetingConfirmed && getFieldValue(471) == 0" type="button" class="btn btn-success"
              @click="confirmMeeting" style="font-size: smaller;">تأكيد الموعد</button>
            <p v-else>تم تأكيد المقابلة</p>
          </div>
          <div class="line"></div>
          <div class="step">
            <div class="circle completed"><i class="fa-solid fa-check"></i></div>
            <p>تحت الإجراء</p>
          </div>
          <div class="line"></div>
          <div class="step">
            <div class="circle completed"><i class="fa-solid fa-check"></i></div>
            <p>تم استلام الطلب</p>
          </div> -->

          <div class="content">
            <p>
              نشكركم على اهتمامكم بالمشاركة في البرنامج التدريبي الخاص بمبادرة "تمكين 3" بالمؤسسة الليبية للاستثمار،
              وحرصكم على تطوير مهاراتكم وخبراتكم. ونود آسفين إبلاغكم بتعذر قبولكم ضمن المبادرة لعدم اجتياز مرحلة
              المقابلة الشخصية بنجاح.
              <br>
              <strong>
                نقدّر جهودكم ونتمنى لكم التوفيق
                <br>
                مع خالص الشكر</strong>
            </p>
          </div>
        </div>
        <div class="steps" v-if="getFieldValue(469) == 3">

          <div class="step">
            <div class="circle" style="background-color: green;"><i class="fa-regular fa-handshake"></i></div>
            <p>تهنينا تم قبولك </p>
          </div>
          <div class="line"></div>
          <div class="step">
            <div class="circle completed"><i class="fa-solid fa-check"></i></div>
            <p>المقابلة الشخصية</p>
          </div>
          <div class="line"></div>
          <div class="step">
            <div class="circle completed"><i class="fa-solid fa-check"></i></div>
            <p>تحت الإجراء</p>
          </div>
          <div class="line"></div>
          <div class="step">
            <div class="circle completed"><i class="fa-solid fa-check"></i></div>
            <p>تم استلام الطلب</p>
          </div>
        </div>
        <!-- رفض -->
        <div v-if="getFieldValue(469) == -1">
          <div class="content">
            <p>
              نشكركم على اهتمامكم بالمشاركة في البرنامج التدريبي الخاص بمبادرة "تمكين 3" بالمؤسسة الليبية للاستثمار،
              وحرصكم على تطوير مهاراتكم وخبراتكم.
              <br>
              ونود آسفين إبلاغكم بتعذر قبولكم ضمن المبادرة لعدم استيفاء الشروط
              والمعايير العامة المطلوبة.
              <br>
              <strong>
                نقدّر جهودكم ونتمنى لكم التوفيق
                <br>
                مع خالص الشكر</strong>
            </p>
          </div>
        </div>
        <!-- رفض -->
      </div>
    </div>
    <br><br><br><br><br><br><br><br><br><br><br><br><br><br><br><br>
  </div>
</template>

<script>
import axios from 'axios';

export default {
  data() {
    return {
      entry_id: '',
      item_key: '',
      entry: null,
      errorMessage: '',
      meetingConfirmed: false,
    };
  },
  methods: {
    async login() {
      try {
        // إزالة 'lia24' من entry_id
        const cleanedEntryId = this.entry_id.replace('lia', '');

        // التأكد من أن item_key يتطابق تمامًا مع النمط L*jurai$
        const keyMatch = this.item_key.match(/^L\*([^*]+)\$$/);
        if (!keyMatch) {
          this.errorMessage = ' كلمة المرور غير صحيحة';
          return;
        }

        const extractedKey = keyMatch[1]; // استخراج الكود (jurai)

        const response = await axios.post('https://tamkeen.lia.ly/api/loginUser', {
          entry_id: cleanedEntryId,
          item_key: extractedKey, // استخدم القيمة المستخرجة فقط
        });

        this.entry = response.data.entry;
        this.errorMessage = '';
        this.setCurrentStep();
      } catch (error) {
        this.errorMessage = 'خطأ في البيانات المدخلة ';
        // this.entry = null;
      }
    },


    getFieldValue(fieldId) {
      const field = this.entry.find(item => item.field_id == fieldId);
      return field ? field.field_value : '';
    },
    async confirmMeeting() {
      try {
        const response = await axios.post('https://tamkeen.lia.ly/api/meeting_accsept', {
          entry_id: this.entry_id
        });
        if (response.status === 200) {
          this.meetingConfirmed = true;
        }
      } catch (error) {
        console.error('Error confirming meeting:', error);
      }
    }
  },
};
</script>

<style scoped>
/* Add your custom styles here */
.container {
  max-width: 100%;
  font-size: 14px;
  background-color: #f8f9fa;
  min-height: 100%;
}

.button-17 {
  align-items: center;
  appearance: none;
  background-color: #fff;
  border-radius: 24px;
  border-style: none;
  box-shadow: rgba(0, 0, 0, .2) 0 3px 5px -1px, rgba(0, 0, 0, .14) 0 6px 10px 0, rgba(0, 0, 0, .12) 0 1px 18px 0;
  box-sizing: border-box;
  color: #556C57;
  cursor: pointer;
  display: inline-flex;
  fill: currentcolor;
  font-size: 14px;
  font-weight: 500;
  height: 48px;
  justify-content: center;
  letter-spacing: .25px;
  line-height: normal;
  max-width: 100%;
  overflow: visible;
  padding: 2px 24px;
  position: relative;
  text-align: center;
  text-transform: none;
  transition: box-shadow 280ms cubic-bezier(.4, 0, .2, 1), opacity 15ms linear 30ms, transform 270ms cubic-bezier(0, 0, .2, 1) 0ms;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  width: auto;
  will-change: transform, opacity;
  z-index: 0;
}

.button-17:hover {
  background: #A3A477;
  color: #f4f4f4;
}

.header {
  background-color: #214439;
  color: white;
  padding: 20px;
  text-align: center;
  height: 300px;
  border-radius: 10px;
}

.header img {
  width: 100px;
  margin-bottom: 10px;
}

.containerDashboard {
  background-color: white;
  max-width: 95%;
  margin: -50px auto 0;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  text-align: center;
  position: relative;
  top: -20px;
}

.title {
  font-size: 24px;
  margin-bottom: 10px;
  text-align: right;
  font-weight: bolder;
}

.subtitle {
  font-size: 15px;
  margin-bottom: 20px;
  color: black;
  text-align: right;

}

.name {
  font-weight: bolder;
  margin: 50px;
  font-size: larger;
  text-align: right;
  direction: rtl;


}

.steps {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 40px;
}

.step {
  text-align: center;
}

.step .circle {
  width: 50px;
  height: 50px;
  background-color: #21412a;
  color: white;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto 10px;
}

.step .circle.completed {
  background-color: #d4af37;
}

.step p {
  margin: 0;
}

.line {
  flex-grow: 1;
  height: 2px;
  background-color: #21412a;
  position: relative;
  top: -25px;
}
</style>
